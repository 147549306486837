import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IResponseDTO } from "../interfaces/response.interface";

@Injectable({
  providedIn: "root",
})
export class MeetingService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  public getMeetings(
    property: string = "",
    limit: number = 10,
    page: number = 1,
  ): Observable<IResponseDTO<any[]>> {
    const body = {
      property,
      limit,
      page,
    };

    return this.http.post<IResponseDTO<any[]>>(
      `${environment.apiUrl}/meetings/search`,
      body,
      {
        withCredentials: true
      },
    );
  }

  public getMotion = (id: string): Observable<any> => {
    return this.http.get<any>(`${this.apiUrl}/meetings/${id}`, {
      withCredentials: true,
    });
  };

  public createMeeting(body: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/meetings`, body);
  }
}
